
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getRights } from '@/api/vip/rights';
import { storeToRefs } from 'pinia';
import { useVip } from '@/pinia/modules/vip/vipRightData';

@Component({
  components: {},
})
export default class SetRule extends Vue {
  is_loading: boolean = false;
  form = {
    free_freight_chance: '',
  };
  get queryType() {
    return this.$route.query.type;
  }
  async created() {
    const rightsData = storeToRefs(useVip()).rightsData.value;
    let data;
    if (rightsData.content) {
      data = JSON.parse(rightsData.content);
    } else {
      const res = await getRights(this.queryType as any);
      data = JSON.parse(res.data.content);
    }
    this.form = {
      free_freight_chance: data.free_freight_chance,
    };
  }
}
