
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { Validate, getValidatorMap } from '@/mixin/validator';
import SingleImageUpload from '@/component/single-image-upload.vue';
import Service from './component/service.vue';
import SetRule from './component/setRule.vue';
import Exclusive from './component/exclusive.vue';
import moment from 'moment';
import { getRights, putRights } from '@/api/vip/rights';
import { message } from 'ant-design-vue';
import { storeToRefs } from 'pinia';
import { useVip } from '@/pinia/modules/vip/vipRightData';

const UPLOAD_DIR = {
  image: 'vip/image',
};
@Component({
  components: {
    SingleImageUpload,
    Service,
    SetRule,
    Exclusive,
  },
})
export default class RightsReduct extends Mixins(Validate) {
  form: any = {
    logo: '',
    name: '',
    description: '',
    status: 1,
    content: '',
    thumb: '',
  };
  UPLOAD_DIR = UPLOAD_DIR;
  preview_visible: boolean = false;
  is_loading: boolean = false;
  preview_image: string = '';
  form_item_layout: any = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  validator_list: any = [
    {
      field: 'description',
      message: '请填写权益说明',
    },
    {
      field: 'logo',
      message: '请上传图片',
    },
    {
      field: 'thumb',
      message: '请上传缩略图',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  get queryType() {
    return this.$route.query.type;
  }
  beforeCommonUpload(file: any) {
    console.log(file);
  }
  back() {
    this.$router.go(-1);
  }
  async save() {
    if (!this.validateCommit()) return this.$message.error('必填项未填写！');
    let res;
    const formData = {
      description: this.form.description,
      logo: this.form.logo,
      thumb: this.form.thumb,
    };
    if (this.queryType === '1') {
      const data = {
        ...formData,
        content: {},
      };
      res = await putRights(this.queryType, data);
    }
    if (this.queryType === '2') {
      const { form, validateCommit } = this.$refs.service as any;
      if (!validateCommit()) return this.$message.error('请输入客服电话!');
      if (!this.checkPhone(form.hotline)) return this.$message.error('请输入有效号码！');
      const data = {
        ...formData,
        content: {
          ...form,
          service_time_start: moment(form.service_time_start).format('HH:mm'),
          service_time_end: moment(form.service_time_end).format('HH:mm'),
        },
      };
      res = await putRights(this.queryType, data);
    }
    if (this.queryType === '3') {
      const { form: ruleForm } = this.$refs.setrule as any;
      const data = {
        ...formData,
        content: {
          free_freight_chance: ruleForm.free_freight_chance,
          free_fee: ruleForm.free_fee,
        },
      };
      res = await putRights(this.queryType, data);
    }
    if (this.queryType === '4') {
      const data = {
        ...formData,
        content: {},
      };
      res = await putRights(this.queryType, data);
    }
    if (res?.status === 200) {
      this.$message.success('保存成功!');
      this.$router.go(-1);
      return;
    }

    this.$message.error((res as any).message);
  }
  checkPhone(mobile: any) {
    const tel = /^0\d{2,3}-?\d{7,8}$/;
    const phone = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    if (mobile.length == 11) {
      //手机号码
      if (phone.test(mobile)) {
        return true;
      }
    } else if (mobile.length == 13 && mobile.indexOf('-') != -1) {
      //电话号码
      if (tel.test(mobile)) {
        return true;
      }
    }
    return false;
  }
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  @changeLoading(['is_loading'])
  handlePreviewCancel() {
    this.preview_visible = false;
  }
  async initRightsData() {
    const rightsData = storeToRefs(useVip()).rightsData.value;
    if (!rightsData.name) {
      const res = await getRights(this.queryType as any);
      this.form = {
        name: res.data.name,
        description: res.data.description,
        logo: res.data.logo,
        status: res.data.status === 1 ? true : false,
        content: res.data.content,
        thumb: res.data.thumb || '',
      };
      return;
    }
    this.form = {
      name: rightsData.name,
      description: rightsData.description,
      logo: rightsData.logo,
      status: rightsData.status === 1 ? true : false,
      content: rightsData.content,
      thumb: rightsData.thumb || '',
    };
  }
  async created() {
    // const res = await getRights(this.queryType+"")
    this.initRightsData();
  }
}
