
import EditableCell from '@/component/editable-cell.vue';

import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '../../../util/decorators';
import { getSimpleCategory } from '@/api/ware/category';
import { putWareRights} from '@/api/vip/rights';

import {
    STATUS_LIST,
    STATUS_LIST_MAP,
} from '../../ware/constant';
import {
    queryWare,
    queryWareConnect
} from '@/api/ware/management';


@Component({
    components: {
        EditableCell
    },
})
export default class Exclusive extends Vue {
    form: any = {
        ware_id: '', // 商品ID
        ware_name: '', // 商品名称
        ware_category_id: [0], // 商品分类
        status: 0
    };
    visible: boolean = false
    ware_table: RemotePagination<WareData> = {
        detail: [],
        current: 1,
        last: 1,
        limit: 10,
        total: 0,
    };
    ware_vip_table: RemotePagination<WareData> = {
        detail: [],
        current: 1,
        last: 1,
        limit: 10,
        total: 0,
    };
    is_loading: boolean = false
    is_table_loading: boolean = false
    status_list_map: any = STATUS_LIST_MAP;
    status_list: any = STATUS_LIST
    selected_id_list: any = []
    selected_data: any = []
    all_ware_data: any = [];
    ware_category_list: any = [];
    status: number = 0

    next_page: number = 1;
    get wareCurrentPageSelectedIdList(): number[] {
        const selected_id_list = this.selected_id_list;
        const current_page_ware_data_list = this.all_ware_data[this.next_page];

        const current_page_selected_id_list: number[] = current_page_ware_data_list
            ? current_page_ware_data_list
                .filter((item: any) => !!selected_id_list.find((id: number) => id === item.id))
                .map((item: any) => item.id)
            : [];
        return current_page_selected_id_list;
    }
    get wareRowSelection(): object {
        return {
            selectedRowKeys: this.wareCurrentPageSelectedIdList,
            onSelect: (record: any, selected: boolean) => {
                if (selected) {
                    if (this.selected_id_list.indexOf(record.id) === -1) {
                        this.selected_data.push(record);
                        this.selected_id_list.push(record.id);
                    }
                } else {
                    const index = this.selected_id_list.indexOf(record.id);
                    this.selected_id_list.splice(index, 1);
                    this.selected_data.splice(index, 1);
                }
            },
            onSelectAll: (selected: boolean, selected_rows: any, change_rows: any) => {
                if (selected) {
                    this.selected_id_list = [
                        ...new Set([...this.selected_id_list, ...selected_rows.map((item: any) => item.id)]),
                    ];
                } else {
                    this.selected_id_list = this.selected_id_list.filter(
                        (id: number) => !change_rows.find((item: any) => item.id === id)
                    );
                }
                this.selected_data = null;
                this.selected_data = selected_rows;
            },
        };
    }
    get queryType(){
        return this.$route.query.type
    }
    // 选择商品分类
    onWareCategoryChange(value: any) {
        this.form.ware_category_id = value;
    }
    async vipConfirm(data: any,value: any){
        if(data.vip_product.price /100 == value){
            this.$message.warning(`会员专享价最高：${value}`)
        }
        const res = await putWareRights((this.queryType as string),{
            edit:[{
                id:data.vip_product.id,
                price:Math.round(value * 100)
            }]
        })
        if(res.status===200){
            this.$message.success("编辑成功！")
            this.fetchVipFilterWareList()
            return
        }
        this.$message.error("编辑失败！")
        
    }
    async handleOk() {
        this.initSearch()
        const data: any[] = []
        this.selected_data.forEach((item: any)=>{
            data.push(
                {
                    product_id:item.id ,
                    price:item.price
                }
            )
        })
        const res = await putWareRights((this.queryType as string),{
            add:data
        })
        if(res.status === 200){
            this.$message.success("添加成功!")
            this.visible = false
            this.fetchVipFilterWareList()
            this.selected_id_list = []
            return
        }
        this.$message.error((res as any ).message)


    }
    async handleDelete(id: any){
        const res = await putWareRights((this.queryType as string),{
            del:[id]
        })
        if(res.status===200){
            this.$message.success("删除成功！")
            this.fetchVipFilterWareList()
            return
        }
        this.$message.error("删除失败！")
    }
    search() {
        this.fetchFilterWareList()
    }
    @changeLoading(['is_loading'])
    async fetchFilterWareList(current: number = 1) {
        const send_data: any = {
            product_id: this.form.ware_id,
            product_name: this.form.ware_name,
            category_id:
                this.form.ware_category_id[0] === 0
                    ? ''
                    : this.form.ware_category_id[this.form.ware_category_id.length - 1],
            is_show: this.form.status === 0?"":this.form.status,
            is_vip:2,
            limit:10,
            filter_promotion_product:1 
        };
        for (const key in send_data) {
            if (send_data[key] === '') delete send_data[key];
        }
        this.ware_table = await queryWare(current, send_data);
        this.getParentCategory();
        this.all_ware_data[this.next_page] = this.ware_table.detail;
    }
    @changeLoading(['is_table_loading'])
    async fetchVipFilterWareList(current: number = 1) {
        const send_data: any = {
            is_vip:1,
            limit:10,
            is_show:this.status
        };
        if(send_data.is_show === 0) delete send_data.is_show
        this.ware_vip_table = await queryWare(current, send_data);
    }
    query(){
        this.fetchVipFilterWareList()
    }
    // 获取商品父级分类
    getParentCategory() {
        this.ware_table.detail.forEach((ware: any) => {
            this.ware_category_list.map((ware_category: any) => {
                if (ware_category.id === ware.category.parent_id && ware.category.parent_id !== 0) {
                    ware.category.parent_category = `${ware_category.name} /`;
                }
            });
        });
    }
    addWare(){
        this.visible = true
        this.fetchFilterWareList()

    }
    // 获取商品分类列表
    async fetchWareCategoryList() {
        this.ware_category_list = await getSimpleCategory();
        this.ware_category_list.unshift({
            id: 0,
            name: '全部',
        });
    }
    onTableChange(pagination: any) {
        this.next_page = pagination.current;
        this.fetchFilterWareList(this.next_page);
    }
    onTableVipChange(pagination: any) {
        this.fetchVipFilterWareList(pagination.current)

    }
    initSearch(){
        this.form={
            ware_id: '', // 商品ID
            ware_name: '', // 商品名称
            ware_category_id: [0], // 商品分类
            status: 0
        }
    }
    modal_cancel(){
        this.initSearch()
        this.selected_id_list = []
        this.visible = false
    }
    paginationOption(data: RemotePagination<WareData>) {
        return {
            total: data.total || 0,
            showTotal: () => `共${data.total}条，每页${data.limit}条`,
            pageSize: data.limit || 1,
            current: Number(data.current) || 1,
        };
    }

    async created() {
        this.fetchWareCategoryList()
        this.fetchVipFilterWareList()
    }
}
