
import { Component, Mixins ,Prop} from 'vue-property-decorator';
import SingleImageUpload from '@/component/single-image-upload.vue';
import { Validate, getValidatorMap } from '@/mixin/validator';
import moment from 'moment';
import { getRights} from '@/api/vip/rights';
import { useVip } from '@/pinia/modules/vip/vipRightData';
import { storeToRefs } from 'pinia';



const UPLOAD_DIR = {
    wechat_qrcode: 'vip/wechatImg',
};
@Component({
    components: {
        SingleImageUpload,
    },
})
export default class Service extends Mixins(Validate) {
    @Prop({ type: String, default: '' }) content!: string;
    is_loading: boolean = false;
    preview_visible: boolean = false;
    preview_image: string = '';
    form: any = {
        wechat_qrcode: "",
        wechat_number: "",
        service_time_start: "",
        service_time_end: "",
        hotline: ""
    }
    validator_list: any = [
        {
            field: 'hotline',
            message: '请输入客服电话',
        }
    ];
    get UPLOAD_DIR() {
        return UPLOAD_DIR;
    }
    get queryType(){
        return this.$route.query.type
    }
    validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
    handlePreviewCancel() {
        this.preview_visible = false;
    }
    handlePreview(imageUrl: string) {
        this.preview_image = imageUrl;
        this.preview_visible = true;
    }
    async created() {
        const   rightsData   = storeToRefs(useVip()).rightsData.value
        let data 
        if(rightsData.content){
             data =JSON.parse(rightsData.content)
        }else{
            const res = await getRights((this.queryType as any) )
            data = JSON.parse(res.data.content)
        }
        this.form = {
            ...data,
            service_time_start:data.service_time_start? moment(data.service_time_start,"HH:mm"):"" ,
            service_time_end:data.service_time_start? moment(data.service_time_end,"HH:mm"):"" 
        }
    }
}
