import { render, staticRenderFns } from "./rightsRedact.vue?vue&type=template&id=66b5eaee&scoped=true"
import script from "./rightsRedact.vue?vue&type=script&lang=ts"
export * from "./rightsRedact.vue?vue&type=script&lang=ts"
import style1 from "./rightsRedact.vue?vue&type=style&index=1&id=66b5eaee&prod&module=true&lang=stylus"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "66b5eaee",
  null
  
)

export default component.exports